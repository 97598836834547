import { Dispatch, SetStateAction, useRef, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Box,
  Divider,
  IconButton,
  List,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material'
import { useAuthContext } from 'context/AuthContext'
import { useRouter } from 'next/router'
import { menuItems } from 'templates/Layout/Layout'

import Can from 'components/Can'
import CanLogical from 'components/CanLogical'
import { Menu } from 'components/Drawer/components/Menu/Menu'
import InviteAndEarn from 'components/InviteAndEarn'

import { formatMoney } from 'utils'

import { theme } from 'styles/theme'

import MerchantAccount from '../MerchantAccount'
import { Setting } from '../MerchantAccount/MerchantAccount'
import Shortcuts from '../Shortcuts'
import * as S from './MobileDropdown.styles'

interface MobileDropdownProps {
  balance: false | number
  setIsOpenModalReport: Dispatch<SetStateAction<boolean>>
  handleOpenMenuDetails: () => void
  setIsExpandedMobileMenu: Dispatch<SetStateAction<boolean>>
  isExpandedMobileMenu: boolean
}

const MobileDropdown = ({
  balance,
  setIsOpenModalReport,
  handleOpenMenuDetails,
  setIsExpandedMobileMenu,
  isExpandedMobileMenu
}: MobileDropdownProps) => {
  const [openMenu, setOpenMenu] = useState({
    clients: false,
    receives: false
  })

  const wrapperRef = useRef<HTMLDivElement>(null)

  const routerGlobal = useRouter()
  const { signOut, user } = useAuthContext()
  const [hiddedBalance, setHiddenBalance] = useState(false)

  const handleOpenModalReport = () => {
    setIsOpenModalReport(true)
  }

  const handleOpenMenu = (option: string) => {
    setOpenMenu((prevState) => ({
      ...prevState,
      [option]: !openMenu[option]
    }))
  }

  const settings: Setting[] = [
    {
      action: () => {
        routerGlobal.push('/settings')
        setIsExpandedMobileMenu(false)
      },
      icon: 'settings',
      label: 'Configurações'
    },
    {
      action: () => {
        handleOpenModalReport()
      },
      icon: 'assignment',
      label: 'Relatórios'
    },
    {
      action: async () => {
        setIsExpandedMobileMenu(false)
        await signOut()
      },
      icon: 'exitToApp',
      label: 'Sair'
    }
  ]

  if (
    CanLogical({
      roles: ['guest'],
      status: ['trial', 'checking']
    })
  ) {
    const ConfigsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Configurações'
    )
    settings.splice(ConfigsSettingsIndex, 1) // delete 1 item by index of reports

    const balaceSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Esconder Saldo'
    )
    settings.splice(balaceSettingsIndex, 1) // delete 1 item by index of reports

    const reportsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Relatórios'
    )
    settings.splice(reportsSettingsIndex, 1) // delete 1 item by index of reports
  }

  if (
    CanLogical({
      roles: ['operator', 'financial_operator'],
      status: ['trial', 'checking']
    })
  ) {
    const reportsSettingsIndex = settings.findIndex(
      ({ label }) => label === 'Relatórios'
    )
    settings.splice(reportsSettingsIndex, 1) // delete 1 item by index of reports
  }

  return (
    <S.Wrapper ref={wrapperRef} isExpandedMobileMenu={isExpandedMobileMenu}>
      <Shortcuts setIsExpandedMobileMenu={setIsExpandedMobileMenu} />
      <S.AccountDetails>
        <MerchantAccount setIsOpenModalReport={setIsOpenModalReport} />

        <Can roles={['user', 'admin']}>
          <Box display="flex" alignItems="flex-end">
            <Tooltip
              enterTouchDelay={0}
              title={Number(balance) > 0 ? 'Visualizar Detalhes' : ''}
            >
              <S.BalanceButton variant="text" onClick={handleOpenMenuDetails}>
                <Typography variant="smLight">Saldo</Typography>

                {balance === false && (
                  <Skeleton
                    variant="rectangular"
                    width={64}
                    height={23}
                    sx={{ borderRadius: 2 }}
                  />
                )}

                {balance !== false && (
                  <Typography onClick={handleOpenMenuDetails} variant="mdBold">
                    {hiddedBalance ? 'R$ *****' : formatMoney(Number(balance))}
                  </Typography>
                )}
              </S.BalanceButton>
            </Tooltip>

            <Tooltip
              enterTouchDelay={0}
              title={hiddedBalance ? 'Mostrar saldo' : 'Esconder saldo'}
            >
              <IconButton onClick={() => setHiddenBalance(!hiddedBalance)}>
                <Icon icon={hiddedBalance ? 'visibilityOff' : 'visibility'} />
              </IconButton>
            </Tooltip>
          </Box>
        </Can>
      </S.AccountDetails>
      <Divider sx={{ width: '100%' }} />
      <Box width="100%">
        <List sx={{ width: '100%', gap: '12px' }} component="nav">
          {menuItems[user?.role || 'guest'].map((item, index) => (
            <Menu
              icon={item?.icon}
              key={index}
              label={item?.label}
              handleClick={() => {
                setIsExpandedMobileMenu(false)
              }}
              router={item.router}
              isExpanded={true}
              title={item?.label}
              subheader={item?.subheader}
              openMenu={openMenu}
              handleOpenMenu={handleOpenMenu}
              option={item?.option}
            />
          ))}
          <InviteAndEarn />
        </List>
      </Box>
      <Divider sx={{ width: '100%' }} />
      <Stack alignSelf={'flex-start'} width="100%" mb={7}>
        {settings.map((setting) => {
          return (
            <S.MenuItem key={setting.label} onClick={setting.action}>
              {!!setting.icon && (
                <Icon
                  icon={setting.icon}
                  width="23px"
                  sx={{ color: theme.palette.neutral[100] }}
                />
              )}
              <Typography color={theme.palette.neutral[100]} fontWeight={600}>
                {setting.label}
              </Typography>
            </S.MenuItem>
          )
        })}
      </Stack>
    </S.Wrapper>
  )
}

export default MobileDropdown
