import React, { memo, useEffect, useState } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Divider,
  IconButton,
  Skeleton,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { DataService } from 'api/DataService'
import { parseCookies } from 'nookies'

import Can from 'components/Can'
import Logo from 'components/Logo'
import { ModalBalance } from 'components/Modal/ModalBalance'
import ModalReport from 'components/Modal/ModalReport'
import { QuotaParams } from 'components/TopBarGlobalInfo'
import ActivateButton from 'components/TopBarGlobalInfo/components/ActivateButton'

import { formatMoney } from 'utils/FormatMoney'

import { theme } from 'styles/theme'

import * as S from './AppBar.styles'
import Help from './components/Help'
import MerchantAccount from './components/MerchantAccount'
import MobileDropdown from './components/MobileDropdown'
import Search from './components/Search'
import Shortcuts from './components/Shortcuts'
import Updates from './components/Updates'

type AppBarProps = {
  showTopGlobalInfo?: boolean
  setDrawerIsOpen: (drawerIsOpen: boolean) => void
  isExpandedDrawer: boolean
  setIsExpandedMobileMenu: React.Dispatch<React.SetStateAction<boolean>>
  isExpandedMobileMenu: boolean
  freeTier: boolean
  setQuota: React.Dispatch<React.SetStateAction<QuotaParams>>
}

const AppBar = ({
  showTopGlobalInfo,
  isExpandedMobileMenu,
  setIsExpandedMobileMenu,
  isExpandedDrawer,
  freeTier,
  setQuota
}: AppBarProps) => {
  const [refresh, setRefresh] = useState(false)

  const [isOpenModalReport, setIsOpenModalReport] = useState(false)
  const [balance, setBalance] = useState<false | number>(false)
  const [balanceScheduled, setBalanceScheduled] = useState<false | number>(
    false
  )
  const isMobile = useMediaQuery('(max-width:800px)')
  const [hiddedBalance, setHiddenBalance] = useState(false)
  const [modalDetailsIsOpen, setModalDetailsIsOpen] = useState(false)
  const refreshTable = () => setRefresh((s) => !s)
  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  const handleCloseModalReport = () => {
    setIsOpenModalReport(false)
  }

  const handleOpenMenuDetails = () => {
    setModalDetailsIsOpen(true)
  }

  const handleCloseModalDetails = () => {
    setModalDetailsIsOpen(false)
    refreshTable()
  }

  const handleToggleMobileMenu = () => setIsExpandedMobileMenu((s) => !s)

  const handleBalace = async () => {
    const res = await DataService({
      type: 'GET',
      url: '/v1/merchants/balance'
    })

    setBalance(Number(res.data.balance))
    setBalanceScheduled(Number(res.data.balance_scheduled))
  }
  const { ['nextauth.status']: status } = parseCookies()
  useEffect(() => {
    handleBalace()
  }, [refresh])

  const getData = async () => {
    const response = await DataService({
      type: 'GET',
      url: `/v1/onboarding/get_status_activation`
    })
    setQuota({
      stage: response.data.record.stage,
      free_tier: response.data.record.free_tier,
      expiresIn: response.data.record.free_tier_end_date,
      billing: {
        max: response.data.record.free_tier_billing_quota,
        actual:
          response.data.record.free_tier_billing_quota -
          response.data.record.free_tier_billing_current_quota
      },
      nfse: {
        max: response.data.record.free_tier_nfse_quota,
        actual:
          response.data.record.free_tier_nfse_quota -
          response.data.record.free_tier_nfse_current_quota
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      <S.AppBarMUI
        showTopGlobalInfo={showTopGlobalInfo}
        position="fixed"
        isExpandedDrawer={isExpandedDrawer}
        isExpandedMobileMenu={match && isExpandedMobileMenu}
      >
        <S.Toolbar isExpandedMobileMenu={match && isExpandedMobileMenu}>
          {!match && (
            <Box
              display="flex"
              alignItems="end"
              gap="22px"
              minWidth="30%"
              width="100%"
              mr={3}
            >
              <Search />
              <Divider
                sx={{
                  borderColor: theme.palette.neutral[500],
                  margin: '8px 0px'
                }}
                flexItem
                orientation="vertical"
              />
              <Shortcuts />
            </Box>
          )}
          {status === 'trial' && !freeTier && !isMobile && (
            <ActivateButton isAppBarButton />
          )}
          {!match ? (
            <S.ToolbarContentWrapper>
              {/* <Weather /> - Comentado em 17/10/2023 */}
              <Can roles={['user', 'admin']}>
                <Box display="flex" alignItems="center">
                  <Tooltip title="Visualizar Detalhes">
                    <S.BalanceButton
                      variant="text"
                      onClick={handleOpenMenuDetails}
                    >
                      <Typography
                        color={theme.palette.neutral[400]}
                        variant="smLight"
                      >
                        Saldo
                      </Typography>

                      {balance === false && (
                        <Skeleton
                          variant="rectangular"
                          width={64}
                          height={23}
                          sx={{ borderRadius: 2 }}
                        />
                      )}

                      {balance !== false && (
                        <Typography
                          sx={{
                            whiteSpace: 'nowrap',
                            display: 'flex'
                          }}
                          onClick={handleOpenMenuDetails}
                          variant="mdBold"
                          color={theme.palette.neutral[400]}
                        >
                          {hiddedBalance
                            ? 'R$ *****'
                            : formatMoney(Number(balance))}
                        </Typography>
                      )}
                    </S.BalanceButton>
                  </Tooltip>
                  <Tooltip
                    enterTouchDelay={0}
                    title={hiddedBalance ? 'Mostrar saldo' : 'Esconder saldo'}
                  >
                    <IconButton
                      onClick={() => setHiddenBalance(!hiddedBalance)}
                    >
                      <Icon
                        icon={hiddedBalance ? 'visibilityOff' : 'visibility'}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Can>
              <Divider
                sx={{
                  borderColor: theme.palette.neutral[500]
                }}
                flexItem={true}
                orientation="vertical"
              />
              <S.EndIconsWrapper>
                <Tooltip title="Ajuda">
                  <div>
                    <Help />
                  </div>
                </Tooltip>
                <Tooltip title="Novidades">
                  <div>
                    <Updates />
                  </div>
                </Tooltip>

                <Tooltip title="Configurações">
                  <div>
                    <MerchantAccount
                      setIsOpenModalReport={setIsOpenModalReport}
                    />
                  </div>
                </Tooltip>
              </S.EndIconsWrapper>
            </S.ToolbarContentWrapper>
          ) : (
            <Box
              width={'100%'}
              display="flex"
              flexDirection="column"
              alignItems="center"
              alignSelf="start"
            >
              <S.MobileWrapper>
                <Logo id="responsive-logo" />
                <S.Actions>
                  {status === 'trial' && !freeTier && isMobile && (
                    <ActivateButton isAppBarButton />
                  )}
                  <Search />
                  <Updates />
                  <IconButton onClick={handleToggleMobileMenu}>
                    <FontAwesomeIcon
                      icon={faBars}
                      style={{
                        width: 25,
                        height: 25,
                        fontSize: 22,
                        color: '#777877'
                      }}
                    />
                  </IconButton>
                </S.Actions>
              </S.MobileWrapper>

              <MobileDropdown
                balance={balance}
                setIsOpenModalReport={setIsOpenModalReport}
                handleOpenMenuDetails={handleOpenMenuDetails}
                setIsExpandedMobileMenu={setIsExpandedMobileMenu}
                isExpandedMobileMenu={isExpandedMobileMenu}
              />
            </Box>
          )}
        </S.Toolbar>
        {/*match && isExpandedMobileMenu && <GlobalSearch />*/}
      </S.AppBarMUI>

      {isOpenModalReport && (
        <ModalReport
          handleCloseReportModal={handleCloseModalReport}
          isOpenModalReport={isOpenModalReport}
        />
      )}

      {modalDetailsIsOpen && (
        <Can roles={['admin', 'user']}>
          <ModalBalance
            balance={balance}
            balanceScheduled={balanceScheduled}
            handleClose={handleCloseModalDetails}
            isOpen={modalDetailsIsOpen}
          />
        </Can>
      )}
    </>
  )
}
export default memo(AppBar)
