import { memo, useCallback, useEffect, useState } from 'react'
//import Carousel from 'react-material-ui-carousel'
import { useIntercom } from 'react-use-intercom'

import { Loader } from '@clientbase/clientbase-library'
import { Box, useMediaQuery } from '@mui/material'
import { useAuthContext } from 'context/AuthContext'
import jwt_decode from 'jwt-decode'
import { Banner } from 'models/Banner'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { MerchantProps } from 'pages/_app'

import AppBar from 'components/AppBar'
import { Drawer, Item } from 'components/Drawer'
import ModalPricing from 'components/Modal/ModalPricing'
import ModalSurveyNPS from 'components/Modal/ModalSurveys/ModalSurveyNPS'
import NewsBanner from 'components/NewsBanner'
import TopBarGlobalInfo, { QuotaParams } from 'components/TopBarGlobalInfo'

import getCurrentMonth from 'utils/get-current-month'
import { ChargeTabs, CustomerTabs } from 'utils/tabs'

import { theme } from 'styles/theme'

import { BackgroundBlur, Content, Wrapper } from './Layout.styles'

const defaultMenuItems: Item[] = [
  {
    icon: 'barChart',
    label: 'Visão Geral',
    router: '/visao-geral'
  },
  {
    icon: 'supervisorAccount',
    label: 'Base de Clientes',
    router: '/clientes',
    option: 'clients',
    subheader: [
      {
        label: 'Link de Cadastro',
        query: { tab: CustomerTabs.REGISTRATION_LINK },
        tab: CustomerTabs.REGISTRATION_LINK
      },
      {
        label: 'Clientes',
        query: {
          tab: CustomerTabs.CUSTOMERS
        },
        tab: CustomerTabs.CUSTOMERS
      },
      {
        label: 'Grupos',
        query: { tab: CustomerTabs.GROUPS },
        tab: CustomerTabs.GROUPS
      },
      {
        label: 'Contratos',
        query: { tab: CustomerTabs.CONTRACTS },
        tab: CustomerTabs.CONTRACTS
      }
    ]
  },
  {
    icon: 'event',
    label: 'Recebimentos',
    router: '/cobrancas',
    option: 'receives',
    subheader: [
      {
        label: 'Produtos',
        query: { tab: ChargeTabs.PRODUCTS },
        tab: ChargeTabs.PRODUCTS
      },
      {
        label: 'Link de Pagamento',
        query: { tab: ChargeTabs.PAYMENT_LINK },
        tab: ChargeTabs.PAYMENT_LINK
      },
      {
        label: 'Cobranças',
        tab: ChargeTabs.CHARGES,
        query: {
          tab: ChargeTabs.CHARGES,
          due_date_between: getCurrentMonth().join('|')
        }
      },
      {
        label: 'Recorrências',
        query: { tab: ChargeTabs.RECURRENCES },
        tab: ChargeTabs.RECURRENCES
      },
      {
        label: 'Transferências',
        query: {
          tab: ChargeTabs.TRANSFERS,
          transfer_date_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.TRANSFERS
      },
      {
        label: 'Notas Fiscais',
        query: {
          tab: ChargeTabs.NFS,
          created_at_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.NFS
      },
      {
        label: 'Negativação',
        query: { tab: ChargeTabs.NEGATIVATION },
        tab: ChargeTabs.NEGATIVATION
      },
      {
        label: 'Contas a pagar',
        query: { tab: ChargeTabs.BILLS_TO_PAY },
        tab: ChargeTabs.BILLS_TO_PAY,
        news: true
      }
    ]
  },
  {
    icon: 'accountBalance',
    label: 'Relacionamento',
    router: '/relacionamento',
    option: 'portal',
    subheader: [
      { label: 'Comunicação', subRouter: '/comunicacao' },
      { label: 'Personalizar', subRouter: '/personalizar' }
    ]
  }
]

const operator: Item[] = [
  {
    icon: 'supervisorAccount',
    label: 'Base de Clientes',
    router: '/clientes',
    option: 'clients',
    subheader: [
      {
        label: 'Link de Cadastro',
        query: { tab: CustomerTabs.REGISTRATION_LINK },
        tab: CustomerTabs.REGISTRATION_LINK
      },
      {
        label: 'Clientes',
        query: {
          tab: CustomerTabs.CUSTOMERS
        },
        tab: CustomerTabs.CUSTOMERS
      },
      {
        label: 'Grupos',
        query: { tab: CustomerTabs.GROUPS },
        tab: CustomerTabs.GROUPS
      },
      {
        label: 'Contratos',
        query: { tab: CustomerTabs.CONTRACTS },
        tab: CustomerTabs.CONTRACTS
      }
    ]
  },
  {
    icon: 'event',
    label: 'Recebimentos',
    router: '/cobrancas',
    option: 'receives',
    subheader: [
      {
        label: 'Link de Pagamento',
        query: { tab: ChargeTabs.PAYMENT_LINK },
        tab: ChargeTabs.PAYMENT_LINK
      },
      {
        label: 'Notas Fiscais',
        query: {
          tab: ChargeTabs.NFS,
          created_at_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.NFS,
        news: true
      }
    ]
  },
  {
    icon: 'accountBalance',
    label: 'Relacionamento',
    router: '/relacionamento',
    option: 'portal',
    subheader: [{ label: 'Comunicação', subRouter: '/comunicacao' }]
  }
]

const financialOperator: Item[] = [
  {
    icon: 'supervisorAccount',
    label: 'Base de Clientes',
    router: '/clientes',
    option: 'clients',
    subheader: [
      {
        label: 'Link de Cadastro',
        query: { tab: CustomerTabs.REGISTRATION_LINK },
        tab: CustomerTabs.REGISTRATION_LINK
      },
      {
        label: 'Clientes',
        query: {
          tab: CustomerTabs.CUSTOMERS
        },
        tab: CustomerTabs.CUSTOMERS
      },
      {
        label: 'Grupos',
        query: { tab: CustomerTabs.GROUPS },
        tab: CustomerTabs.GROUPS
      },
      {
        label: 'Contratos',
        query: { tab: CustomerTabs.CONTRACTS },
        tab: CustomerTabs.CONTRACTS
      }
    ]
  },
  {
    icon: 'event',
    label: 'Recebimentos',
    router: '/cobrancas',
    option: 'receives',
    subheader: [
      {
        label: 'Produtos',
        query: { tab: ChargeTabs.PRODUCTS },
        tab: ChargeTabs.PRODUCTS
      },
      {
        label: 'Link de Pagamento',
        query: { tab: ChargeTabs.PAYMENT_LINK },
        tab: ChargeTabs.PAYMENT_LINK
      },
      {
        label: 'Cobranças',
        tab: ChargeTabs.CHARGES,
        query: {
          tab: ChargeTabs.CHARGES,
          due_date_between: getCurrentMonth().join('|')
        }
      },
      {
        label: 'Recorrências',
        query: { tab: ChargeTabs.RECURRENCES },
        tab: ChargeTabs.RECURRENCES
      },
      {
        label: 'Notas Fiscais',
        query: {
          tab: ChargeTabs.NFS,
          created_at_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.NFS,
        news: true
      }
    ]
  }
]

const guest: Item[] = [
  {
    icon: 'supervisorAccount',
    label: 'Base de Clientes',
    router: '/clientes',
    option: 'clients',
    subheader: [
      {
        label: 'Link de Cadastro',
        query: { tab: CustomerTabs.REGISTRATION_LINK },
        tab: CustomerTabs.REGISTRATION_LINK
      },
      {
        label: 'Clientes',
        query: {
          tab: CustomerTabs.CUSTOMERS
        },
        tab: CustomerTabs.CUSTOMERS
      },
      {
        label: 'Grupos',
        query: { tab: CustomerTabs.GROUPS },
        tab: CustomerTabs.GROUPS
      },
      {
        label: 'Contratos',
        query: { tab: CustomerTabs.CONTRACTS },
        tab: CustomerTabs.CONTRACTS
      }
    ]
  },
  {
    icon: 'event',
    label: 'Recebimentos',
    router: '/cobrancas',
    option: 'receives',
    subheader: [
      {
        label: 'Produtos',
        query: { tab: ChargeTabs.PRODUCTS },
        tab: ChargeTabs.PRODUCTS
      },
      {
        label: 'Link de Pagamento',
        query: { tab: ChargeTabs.PAYMENT_LINK },
        tab: ChargeTabs.PAYMENT_LINK
      },
      {
        label: 'Cobranças',
        tab: ChargeTabs.CHARGES,
        query: {
          tab: ChargeTabs.CHARGES,
          due_date_between: getCurrentMonth().join('|')
        }
      },
      {
        label: 'Recorrências',
        query: { tab: ChargeTabs.RECURRENCES },
        tab: ChargeTabs.RECURRENCES
      },
      {
        label: 'Transferências',
        query: {
          tab: ChargeTabs.TRANSFERS,
          transfer_date_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.TRANSFERS
      },
      {
        label: 'Notas Fiscais',
        query: {
          tab: ChargeTabs.NFS,
          created_at_between: getCurrentMonth().join('|')
        },
        tab: ChargeTabs.NFS,
        news: true
      }
    ]
  },
  {
    icon: 'accountBalance',
    label: 'Relacionamento',
    router: '/relacionamento',
    option: 'portal',
    subheader: [
      { label: 'Comunicação', subRouter: '/comunicacao' },
      { label: 'Personalizar', subRouter: '/personalizar' }
    ]
  }
]

export const menuItems = {
  admin: defaultMenuItems,
  user: defaultMenuItems,
  guest,
  operator,
  financial_operator: financialOperator
}

export type Components = 'drawer' | 'appbar' | 'footer'

type LayoutProps = {
  children: JSX.Element
}

function Layout({ children }: LayoutProps) {
  const { user, modalPricing, setModalPricing } = useAuthContext()
  const { pathname } = useRouter()

  const { update } = useIntercom()

  const {
    ['nextauth.token']: token,
    ['nextauth.user_logo_url']: imageUrl,
    ['nextauth.user']: name,

    ['nextauth.show_nps']: show_nps
  } = parseCookies()
  const [quota, setQuota] = useState<QuotaParams>()
  const [drawerIsOpen, setDrawerIsOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const [banner, setBanner] = useState<Banner | null>(null)
  const [isBannerOpen, setIsBannerOpen] = useState<boolean>(true)

  const [isExpanded, setIsExpanded] = useState(true)
  const [isExpandedInput, setIsExpandedInput] = useState(false)

  const match = useMediaQuery('(max-width: 770px)', { noSsr: false })

  const handleToggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
    localStorage.setItem('expanded-drawer', String(!isExpanded))
  }

  const expandDrawer = () => {
    setIsExpanded(true)
    localStorage.setItem('expanded-drawer', String(true))
  }

  useEffect(() => {
    if (!match) {
      setIsExpandedInput(false)
    }
  }, [match])

  useEffect(() => {
    const isExpanded = localStorage.getItem('expanded-drawer') === 'true'
    setIsExpanded(isExpanded)

    const bannerString = localStorage.getItem('banner')
    const banners = bannerString
      ? (JSON.parse(bannerString) as Banner[] | Banner)
      : null
    setBanner(Array.isArray(banners) ? banners[0] : banners)
  }, [children])

  const getMerchant = useCallback((token: string) => {
    setLoading(true)

    try {
      const merchant = jwt_decode(token) as MerchantProps
      if (!merchant) return

      update({
        name,
        userId: merchant.merchant_user_uuid,
        company: {
          companyId: merchant.merchant_uuid,
          name
        },
        avatar: {
          type: 'avatar',
          imageUrl
        }
      })
    } catch (err) {
      throw new Error('Merchant is not identified')
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    token ? getMerchant(token) : setLoading(false)
  }, [token])

  if (loading) {
    return <Loader />
  }

  const routesHiddenDrawer = [
    '/cadastro/[uuid]',
    '/cadastro/sucesso',
    '/transfers/[uuid]/report',
    '/forgot-password/[id]',
    '/forgot-password',
    '/login',
    '/login/ativar-conta',
    '/registrar'
  ]

  const routesHiddenAppBar = [
    '/cadastro/[uuid]',
    '/cadastro/sucesso',
    '/transfers/[uuid]/report',
    '/forgot-password/[id]',
    '/forgot-password',
    '/login',
    '/login/ativar-conta',
    '/registrar'
  ]

  const MenuItems = (): Item[] => {
    if (!['operator', 'financial_operator'].includes(user?.role || 'guest')) {
      return menuItems[user?.role || 'guest'].map((item) => {
        return item
      })
    }
    return menuItems[user?.role || 'guest']
  }

  /*
  const showCarousel =
    banners != null &&
    ((pathname == '/visao-geral' && banners.length) ||
      (pathname != '/visao-geral' &&
        banners.filter((b) => b.showOnAllPages).length > 0))
  */

  const showBanner =
    banner &&
    isBannerOpen &&
    (pathname === '/visao-geral' || banner.showOnAllPages)

  const showTopBarGlobalInfo =
    quota?.free_tier &&
    pathname !== '/login' &&
    pathname !== '/registrar' &&
    pathname !== '/cadastro' &&
    pathname !== '/transfers' &&
    pathname !== '/registrar'
  return (
    <Wrapper>
      {show_nps == 'true' && pathname === '/visao-geral' && <ModalSurveyNPS />}
      {showTopBarGlobalInfo && <TopBarGlobalInfo quota={quota} />}

      {!routesHiddenDrawer.includes(pathname) && (
        <Drawer
          showTopGlobalInfo={showTopBarGlobalInfo}
          drawerIsOpen={drawerIsOpen}
          menuItems={MenuItems()}
          setDrawerIsOpen={setDrawerIsOpen}
          isExpanded={isExpanded}
          handleToggleIsExpanded={handleToggleIsExpanded}
          expandDrawer={expandDrawer}
        />
      )}
      {!routesHiddenAppBar.includes(pathname) && (
        <AppBar
          setQuota={setQuota}
          freeTier={!!quota?.free_tier}
          showTopGlobalInfo={showTopBarGlobalInfo}
          setDrawerIsOpen={setDrawerIsOpen}
          isExpandedDrawer={isExpanded}
          setIsExpandedMobileMenu={setIsExpandedInput}
          isExpandedMobileMenu={isExpandedInput}
        />
      )}
      <Content
        sx={{
          ...(showTopBarGlobalInfo && {
            marginTop: '130px',
            [theme.breakpoints.down(770)]: {
              marginTop: '200px'
            }
          })
        }}
        isExpandedInput={isExpandedInput}
        appbarIsNotHidded={!routesHiddenAppBar.includes(pathname)}
      >
        {/*banners && (
          <Box padding="20px 20px 0 20px">
            {showCarousel && (
              <Carousel sx={{ overflow: 'visible' }} interval={4000}>
                {banners
                  .filter(
                    (b) => pathname === '/visao-geral' || b.showOnAllPages
                  )
                  .map((banner) => (
                    <NewsBanner banner={banner} key={banner.link} />
                  ))}
              </Carousel>
            )}
          </Box>
        )*/}
        {showBanner && !routesHiddenAppBar.includes(pathname) && (
          <Box
            padding="20px 20px 0px 20px"
            bgcolor={pathname === '/visao-geral' ? '#f4f8f7' : '#fff'}
          >
            <NewsBanner banner={banner} setIsBannerOpen={setIsBannerOpen} />
          </Box>
        )}
        {isExpandedInput && <BackgroundBlur role="presentation" />}
        {children}
      </Content>

      {modalPricing?.isOpen && (
        <ModalPricing
          {...modalPricing}
          handleCloseModal={() =>
            setModalPricing((prev) => ({ ...prev, isOpen: false }))
          }
        />
      )}
    </Wrapper>
  )
}

export default memo(Layout)
