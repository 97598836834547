import { useState } from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Box, Stack, useMediaQuery } from '@mui/material'
import useTrackGTMEvents from 'hooks/use-track-gtm-events'
import { useRouter } from 'next/router'
import { parseCookies } from 'nookies'
import { ParsedUrlQueryInput } from 'querystring'

import InviteAndEarn from 'components/InviteAndEarn'
import UpgradeButton from 'components/Upgrade/components/UpgradeButton'

import { settingsTabsParams } from 'utils/tabs'

import Logo from '../Logo'
import { Menu } from './components/Menu/Menu'
import * as S from './Drawer.styles'
export type Item = {
  icon: Icons
  label: string
  router: string
  subheader?: SubheaderProps[]
  option?: string
}
export interface SubheaderProps {
  label: string
  icon?: Icons
  query?: ParsedUrlQueryInput
  tab?: number | string
  subRouter?: string
  news?: boolean
  highlight?: boolean
}
export type DrawerProps = {
  showTopGlobalInfo: boolean | undefined
  menuItems: Item[]
  window?: () => Window
  drawerIsOpen: boolean
  setDrawerIsOpen: (drawerIsOpen: boolean) => void
  isExpanded: boolean
  handleToggleIsExpanded: () => void
  expandDrawer: () => void
}
export const Drawer = ({
  showTopGlobalInfo,
  menuItems,
  window,
  setDrawerIsOpen,
  drawerIsOpen,
  handleToggleIsExpanded,
  expandDrawer
}: DrawerProps) => {
  const trackEvent = useTrackGTMEvents()
  const { ['nextauth.user']: user, ['nextauth.current_plan']: current_plan } =
    parseCookies()
  const handleTrack = (event: string, maximizar: boolean) => {
    trackEvent({
      event,
      typeDrawer: maximizar ? 'Minimizou' : 'Maximizou',
      merchant_name: user
    })
  }
  const { push } = useRouter()
  const [openMenu, setOpenMenu] = useState({
    clients: false,
    receives: false
  })
  const container =
    window !== undefined ? () => window().document.body : undefined
  const match = useMediaQuery('(max-width: 700px)', {
    noSsr: window === undefined
  })
  const handleOpenMenu = (option: string) => {
    setOpenMenu((prevState) => ({
      ...prevState,
      [option]: !isExpanded ? true : !openMenu[option]
    }))
  }

  const isExpanded = localStorage.getItem('expanded-drawer') == 'true'
  const drawer = (id: string) => (
    <S.WrapperDrawer sx={{ ...(showTopGlobalInfo && { marginTop: '50px' }) }}>
      <Box width="100%" height="100%">
        {match && (
          <S.CloseButton onClick={() => setDrawerIsOpen(false)}>
            <Icon icon="close" />
          </S.CloseButton>
        )}
        {isExpanded ? (
          <S.LogoWrapper>
            <Logo id={id} />
          </S.LogoWrapper>
        ) : (
          <Box
            width="50px"
            height="60px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mx="auto"
          >
            <Logo id={id} size="only-logo" />
          </Box>
        )}
        <S.HandleToggleIsExpandedButton
          showTopGlobalInfo={showTopGlobalInfo}
          onClick={handleToggleIsExpanded}
        >
          <Icon
            onClick={() => handleTrack('click_on_minimizar_drawer', isExpanded)}
            icon={isExpanded ? 'arrowCircleLeft' : 'arrowCircleRight'}
            width="27px"
            height="27px"
          />
        </S.HandleToggleIsExpandedButton>
        <Box
          sx={{
            transition: 'gap 140ms ease-in-out',
            overflowY: 'scroll',
            '::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent'
            },
            '&:hover': {
              '::-webkit-scrollbar-thumb': {
                backgroundColor: '#DAD7D7'
              }
            }
          }}
          mt="70px"
          display="flex"
          height="calc(100% - 80px)"
          maxHeight="calc(100% - 110px)"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box
            flexDirection="column"
            justifyContent="space-between"
            width="100%"
            display="flex"
            flexWrap="wrap"
            gap={isExpanded ? 1 : 3}
          >
            {menuItems.map((item, index) => (
              <Menu
                icon={item?.icon}
                key={index}
                // label={match ? item?.label : isExpanded ? item?.label : ''}
                label={item?.label}
                handleClick={() => setDrawerIsOpen(false)}
                expandDrawer={expandDrawer}
                router={item.router}
                isExpanded={isExpanded}
                title={isExpanded ? item?.label : ''}
                subheader={item?.subheader}
                openMenu={openMenu}
                handleOpenMenu={handleOpenMenu}
                option={item?.option}
              />
            ))}
          </Box>

          <Box
            mb="10px"
            height={'100%'}
            width={'100%'}
            display={'flex'}
            alignItems={'end'}
            justifyContent="center"
          >
            <Stack gap="10px">
              {isExpanded && !['pro', 'ultra'].includes(current_plan) && (
                <UpgradeButton
                  onClick={() =>
                    push(`/settings?tab=${settingsTabsParams.MY_PLAN}`)
                  }
                />
              )}
              <InviteAndEarn
                handleToggleIsExpanded={handleToggleIsExpanded}
                isExpanded={isExpanded}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </S.WrapperDrawer>
  )
  return (
    <>
      <S.DrawerWrapper
        isExpanded={isExpanded}
        ModalProps={{
          keepMounted: true
        }}
        container={container}
        open={drawerIsOpen}
        sx={{
          display: { xsm: 'none', xs: 'block' },
          flexShrink: 0,
          width: '100%',
          overflowX: 'auto',
          alignItems: 'center'
        }}
        variant="temporary"
        width="100%"
      >
        {drawer('responsive-logo')}
      </S.DrawerWrapper>
      <S.DrawerWrapper
        isExpanded={isExpanded}
        open={!drawerIsOpen}
        sx={{
          display: { xsm: 'block', xs: 'none' },
          flexShrink: 0,
          width: isExpanded ? 260 : 100,
          overflowX: 'auto'
        }}
        variant="permanent"
        width={isExpanded ? 260 : 100}
      >
        {drawer('logo')}
      </S.DrawerWrapper>
    </>
  )
}
