import React from 'react'

import { Button, Icon } from '@clientbase/clientbase-library'
import { useRouter } from 'next/router'

import { theme } from 'styles/theme'

import * as S from './styles'
interface ActivateButtonProps {
  isAppBarButton?: boolean
}
const ActivateButton = ({ isAppBarButton = false }: ActivateButtonProps) => {
  const { push } = useRouter()
  const appBarStyled = {
    bgColor: '#294266',
    color: 'white'
  }
  return (
    <Button
      variantButton="outlined"
      sx={{
        ...(isAppBarButton && {
          bgcolor: appBarStyled.bgColor,
          padding: '20px 14px',
          marginRight: '10px',
          minWidth: '190px',
          [theme.breakpoints.down(800)]: {
            minWidth: 'auto',
            marginLeft: '20px'
          },
          ':hover': {
            backgroundColor: appBarStyled.bgColor,
            padding: '20px 14px'
          }
        }),
        ...(!isAppBarButton && {
          [theme.breakpoints.down(800)]: {
            width: '100%',
            margin: '0px 10px'
          }
        }),
        border: 'none',
        '&:hover': {
          border: 'none'
        }
      }}
    >
      <S.Link
        onClick={() => push('/ativar-conta')}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: '5px',
          fontWeight: '600',
          ...(isAppBarButton && {
            color: appBarStyled.color
          })
        }}
      >
        Concluir cadastro
        <Icon
          onClick={() => push('/ativar-conta')}
          icon="open"
          width="21px"
          height="21px"
          ml="6px"
          sx={{
            cursor: 'pointer',
            color: '#000',
            ...(isAppBarButton && {
              color: appBarStyled.color
            })
          }}
        />
      </S.Link>
    </Button>
  )
}

export default ActivateButton
