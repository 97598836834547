import React from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography
} from '@mui/material'

import { theme } from 'styles/theme'

import CopyLinkField from './components/CopyLinkField'
import * as S from './styles'
type InviteAndEarnProps = {
  isExpanded?: boolean
  handleToggleIsExpanded?: () => void
}
const InviteAndEarn = ({
  isExpanded = true,
  handleToggleIsExpanded
}: InviteAndEarnProps) => {
  const handleClickIcon = () => {
    handleToggleIsExpanded && handleToggleIsExpanded()
  }
  if (!isExpanded)
    return (
      <S.IconWrapper onClick={() => handleClickIcon()}>
        <Icon
          sx={{ cursor: 'pointer' }}
          height="22px"
          width="22px"
          colorSX={theme.palette.neutral[500]}
          icon="faGift"
        />
      </S.IconWrapper>
    )
  return (
    <Box>
      <Accordion
        sx={{
          backgroundColor: theme.palette.neutral[900],
          boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.1)',
          '&.MuiAccordion-root:last-of-type': {
            borderRadius: '10px'
          },
          '&.Mui-expanded:last-of-type': {
            borderRadius: '15px'
          }
        }}
      >
        <AccordionSummary
          expandIcon={<Icon icon="keyboardArrowDown" />}
          aria-controls="accordionContent"
          id="accordionDetails"
          sx={{
            padding: '0px 12px'
          }}
        >
          <Box gap="10px" display="flex" alignItems="center">
            <Icon
              height="17px"
              width="17px"
              colorSX={theme.palette.neutral[500]}
              icon="faGift"
            />
            <Typography variant="mdBold" color={theme.palette.neutral[400]}>
              Indique e ganhe
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ marginTop: '-15px' }}>
          <S.Wrapper>
            <Typography
              variant="smLight"
              color={theme.palette.neutral[400]}
              fontSize="14px"
            >
              Compartilhe o link abaixo e ganhe R$ 100,00 quando cada indicado
              receber R$ 1.000,00 com a Base de Clientes.
            </Typography>
            <CopyLinkField />
            <S.Link
              href="https://www.basedeclientes.com.br/indicacoes"
              target="_blank"
            >
              <Typography
                color={theme.palette.neutral[400]}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                variant="xsLight"
                fontSize="11px"
              >
                Ver regras
              </Typography>
            </S.Link>
          </S.Wrapper>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default InviteAndEarn
