import React, { useState } from 'react'

import { Icon, Icons } from '@clientbase/clientbase-library'
import { Typography } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'

import { theme } from 'styles/theme'

import * as S from './'

type RoundedItemProps = {
  tooltipTitle?: string
  name?: string
  icon?: Icons | JSX.Element
  color?: string
  size?: 'xxs' | 'xs' | 'small' | 'normal'
  disableHover?: boolean
  isNew?: boolean
  isActive?: boolean
  isAddIcon?: boolean
  isAddIconItem?: boolean
  isModalIcon?: boolean
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const RoundedItem = ({
  tooltipTitle,
  name,
  icon,
  color,
  isAddIconItem,
  size = 'small',
  isNew = false,
  disableHover,
  isActive = false,
  isAddIcon = false,
  isModalIcon = false,
  ...props
}: RoundedItemProps) => {
  const [isHovered, setHovered] = useState(false)

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseOut = () => {
    setHovered(false)
  }

  return (
    <Tooltip
      enterTouchDelay={0}
      title={tooltipTitle}
      sx={{ cursor: disableHover ? 'auto' : 'pointer' }}
    >
      <S.Wrapper
        {...props}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {isNew && <S.NewBanner>Novidade</S.NewBanner>}

        <S.IconWrapper
          size={size}
          color={isModalIcon || isAddIcon ? '#000' : theme.palette.neutral[400]}
          isAddIcon={isAddIcon}
          isHovered={disableHover ? false : isActive ? true : isHovered}
        >
          {typeof icon === 'object' ? (
            React.cloneElement(icon, {
              color: isActive
                ? '#FFF'
                : isHovered && !disableHover
                ? '#FFF'
                : color || '#A3A6A2',
              width: 18
            })
          ) : (
            <Icon
              icon={icon as Icons}
              style={{
                color: isAddIcon
                  ? '#000'
                  : isModalIcon
                  ? '#000'
                  : isActive || isAddIcon
                  ? '#FFF'
                  : isHovered && !disableHover
                  ? '#FFF'
                  : color || theme.palette.neutral[400]
              }}
              fontSize="small"
            />
          )}
          {!isAddIcon && !isModalIcon && !isAddIconItem && (
            <Icon
              sx={{
                position: 'absolute',
                top: '15px',
                border: '1px solid #F2F5F2',
                left: '22px',
                backgroundColor: '#F2F5F2',
                borderRadius: '50%',
                fontSize: '16px',
                color: theme.palette.neutral[400]
              }}
              icon={'add'}
            />
          )}
        </S.IconWrapper>
        {name && (
          <Typography variant="smLight" fontSize={10} mt="4px" width="70px">
            {name}
          </Typography>
        )}
      </S.Wrapper>
    </Tooltip>
  )
}

export default RoundedItem
