import { Component as ReactComponent } from 'react'

import { Button, Icon, Textarea } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'
import { getAPIClient } from 'api/axios'
import { filesize } from 'filesize'

import Upload from 'components/UploadComponent/Upload'

import { Container, Content, Props, State } from '.'
class UploadComponent extends ReactComponent<Props, State> {
  state: State = {
    uploadedFiles: [],
    description: '',
    submited: false
  }
  handleDelete = async (id: string) => {
    const file = this.state.uploadedFiles.filter((file) => file.id === id)
    if (!this.props.submit && !this.props.noSuppressAuto && file[0]?.uploaded) {
      const uuid = this?.props?.uuid ? `/${this.props.uuid}` : ''
      getAPIClient().delete(`/v1/${this.props.type}${uuid}/documents/${id}`)
    }

    this.setState({
      uploadedFiles: this.state.uploadedFiles.filter((file) => file.id !== id)
    })

    !!this.props.setStateExternal &&
      this.props.setStateExternal((state) =>
        state.filter((file) => file.id !== id)
      )
  }
  handleUpload = (files: any) => {
    const uploadedFiles = files.map((file: any) => ({
      file,
      id: Math.floor(Math.random() * 100),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null
    }))

    this.setState({
      uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
    })
    if (this.props.setError) {
      this.props.setError(false)
    }

    !!this.props.setStateExternal &&
      this.props.setStateExternal((state) => state.concat(uploadedFiles))

    if (!this.props.submit && !this.props.noSuppressAuto) {
      uploadedFiles.forEach((file: any) =>
        this.processUpload(file, this.props.uuid)
      )
    }
  }

  handleUploadSubmit = () => {
    const { uploadedFiles } = this.state
    if (!uploadedFiles.length) {
      this.props.setError && this.props.setError(true)
      return
    }
    uploadedFiles.forEach((file) => this.processUpload(file, this.props.uuid))
  }

  updateFile = (id: string, data: any) => {
    this.setState({
      uploadedFiles: this.state.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile
      })
    })

    !!this.props.setStateExternal &&
      this.props.setStateExternal((state) =>
        state.map((uploadedFile) => {
          return id === uploadedFile.id
            ? { ...uploadedFile, ...data }
            : uploadedFile
        })
      )
  }

  processUpload = (uploadedFile: any, uuid?: string) => {
    const formData = new FormData()

    const docType = {
      rg: '1',
      cpf: '2',
      cnpj: '3',
      cnh: '4',
      residency_proof: '5',
      activity_proof: '6',
      receipt: '7',
      documet_whith_photo: '8',
      import: '9',
      contract: '10',
      announcement: '11',
      product_image: '13',
      nfse: '18',
      documents: '100',
      other: '100'
    }

    formData.append('document', uploadedFile.file)
    formData.append('doc_type', docType[this.props.docType])
    formData.append('description', this.state.description)

    const uuidReceived = uuid ? `/${uuid}` : ''
    getAPIClient()
      .post(`/v1/${this.props.type}${uuidReceived}/documents`, formData, {
        onUploadProgress: (event: ProgressEvent) => {
          const { loaded, total } = event
          const progress = Math.round((loaded * 100) / total)
          const intProgress = progress.toString()

          this.updateFile(uploadedFile.id, {
            progress: intProgress
          })
        }
      })
      .then((response: any) => {
        this.updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.record.uuid,
          url: response.data.record.doc_url
        })

        if (this.props.resetAfterSubmit) {
          this.setState({
            description: '',
            uploadedFiles: []
          })
          if (this.props.refreshTable) {
            this.props.refreshTable()
            if (this.props.onUploadComplete) {
              this.props.onUploadComplete()
            }
          }
        }
      })
      .catch(() => {
        this.updateFile(uploadedFile.id, {
          error: true
        })
      })
  }
  render() {
    const { uploadedFiles } = this.state
    const documentErrorMessage = 'Você deve selecionar um documento.'

    return (
      <Container sx={{ gap: '8px' }}>
        <Content>
          <Upload
            onUpload={this.handleUpload}
            uploadedFilesLength={uploadedFiles.length}
            title={this.props.title}
            subTitle={this.props.subTitle}
            maxFiles={this.props.maxFiles}
            sentIcon={this.props.sentIcon}
            disabled={this.props.disabled}
            accept={this.props.accept}
            disabledText={this.props.disabledText}
          />

          {!this.props.disableList && !!uploadedFiles.length && (
            <Box padding="5px" display="grid" gridTemplateColumns={'90% 10%'}>
              <Box>
                <Box>
                  <Typography fontSize="12px" color="#999">
                    Nome do arquivo: <strong>{uploadedFiles[0]?.name}</strong>
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize="12px" color="#999">
                    Tamanho: {uploadedFiles[0]?.readableSize}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ cursor: 'pointer' }}
                display="flex"
                justifyContent="end"
                alignItems="center"
              >
                <Icon
                  onClick={() => this.handleDelete(uploadedFiles[0].id)}
                  icon="delete"
                />
              </Box>
            </Box>
          )}
        </Content>
        {this.props.error && (
          <Typography mt="-5px" ml="4px" color="#990017" variant="smBold">
            {documentErrorMessage}
          </Typography>
        )}
        <Box>
          {this.props.description && (
            <Textarea
              onChange={(event) => {
                this.setState({
                  description: event.target.value
                })
              }}
              value={this.state.description}
              placeholder="Descrição"
              height="80px"
              fontSize="14px"
            />
          )}

          {this.props.submit && (
            <Button
              sx={{ mt: '18px', fontWeight: '400' }}
              onClick={this.handleUploadSubmit}
              fullWidth={true}
            >
              Salvar
            </Button>
          )}
        </Box>
      </Container>
    )
  }
}

export default UploadComponent
