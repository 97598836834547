import React, { useEffect, useState } from 'react'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'

import { Loader } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'
import { ReceivablesCalendar } from 'models/Receivables'
import moment from 'moment'
import { ReceivablesService } from 'services/ReceivablesService'

import { ModalBalanceDetail } from 'components/Modal/ModalBalaceDetail/ModalBalaceDetail'

import { formatMoney } from 'utils'

import { EventsCalendar } from './CalendarReceivables.interface'
import * as S from './CalendarReceivables.styles'
import { Language, mountDateCalendar } from './CalendarReceivables.utils'
import ToolbarCalendar from './components/ToolbarCalendar/ToolbarCalendar'

interface CalendarReceivablesProps {
  balanceScheduled: number | false
}

const CalendarReceivables: React.FC<CalendarReceivablesProps> = ({
  balanceScheduled
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [events, setEvents] = useState<EventsCalendar[]>([])
  const [modalBalaceDetail, setModalBalaceDetail] = useState({
    isOpen: false,
    date: ''
  })

  const localizer = momentLocalizer(moment)

  function handleCloseModalBalaceDetail() {
    setModalBalaceDetail({
      date: '',
      isOpen: false
    })
  }

  function mountEvents(
    events: Array<ReceivablesCalendar>
  ): Array<EventsCalendar> {
    return events.map(({ amount, date }, index) => {
      const dateSplited = date.split('-')
      return {
        id: index + 1,
        start: mountDateCalendar(dateSplited, true),
        end: mountDateCalendar(dateSplited, false),
        title: `${formatMoney(amount)}`
      }
    })
  }

  function handleOpenDetailOfTheDay(event: EventsCalendar) {
    setModalBalaceDetail({
      isOpen: true,
      date: moment(event.start).format('YYYY-MM-DD')
    })
  }

  async function getInitial() {
    try {
      setLoading(true)

      const response = await ReceivablesService.getCalendarDetails()

      setEvents(mountEvents(response))
    } catch (err) {
      //
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getInitial()
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <S.Wrapper>
      <Box mt="8px" mb="8px">
        <Typography variant="mdBold">
          Saldo: {formatMoney(Number(balanceScheduled))}
        </Typography>
      </Box>
      <Calendar
        localizer={localizer}
        events={events}
        messages={Language}
        onSelectEvent={handleOpenDetailOfTheDay}
        views={[Views.MONTH, Views.AGENDA]}
        components={{
          toolbar: (props) => (
            <ToolbarCalendar balanceScheduled={balanceScheduled} {...props} />
          )
        }}
      />

      <ModalBalanceDetail
        handleClose={handleCloseModalBalaceDetail}
        date={modalBalaceDetail.date}
        isOpen={modalBalaceDetail.isOpen}
      />
    </S.Wrapper>
  )
}

export default CalendarReceivables
